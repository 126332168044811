import React from 'react';
import styles from './ThankYou.less';

class ThankYou extends React.PureComponent {
  render() {
    return (
      <div className={styles.wrap}>
        <div className={styles.content}>
          <h3>Thank you! </h3>
          <p>Your code is on the way.</p>
          <p>Please check your inbox.</p>
        </div>
      </div>
    );
  }
}

export default ThankYou;
